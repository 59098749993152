import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import logo from "./assets/images/nonstock.png";
import feature from "./assets/images/feature.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

const ComingSoon: React.FC = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState<string | null>(null); // For displaying messages
  const [messageType, setMessageType] = useState<"error" | "success" | null>(
    null
  );

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://lgnxdgeormimytauccac.supabase.co/functions/v1/subscribeNewsletter",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      const result = await response.json(); // Get the response JSON

      if (response.ok) {
        setEmail(""); // Clear email input
        setMessage(result.message); // Set success message
        setMessageType("success"); // Set message type to 'success'
      } else {
        setMessage(result.error || "Failed to submit email"); // Set error message
        setMessageType("error"); // Set message type to 'error'
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("An error occurred. Please try again."); // General error message
      setMessageType("error");
    }
  };

  return (
    <div className="bg-[radial-gradient(ellipse_at_top_right,_var(--tw-gradient-stops))] from-rose-400 via-orange-300 to-orange-100 min-h-screen flex flex-col relative">
      <Helmet>
        <title>NonStock - Coming Soon</title>
        <meta
          name="description"
          content="Stay tuned for our upcoming launch. Enter your email to get notified!"
        />
      </Helmet>

      <div className="mt-8 right-auto flex justify-center w-full mb-12 md:mb-0">
        <Link to="/">
          <img src={logo} alt="Logo" className="h-6 w-auto" />
        </Link>
      </div>

      <div className="flex flex-col md:flex-row justify-center items-center mx-auto min-h-screen px-6 md:space-x-24">
        <div className="text-center md:text-left md:w-1/2 mb-8 md:mb-0 md:mr-16">
          <p className="font-medium text-white mb-4 uppercase">
            👨‍🍳 We're Still
          </p>
          <h1 className="font-bold text-4xl md:text-6xl lg:text-7xl mb-4 text-white">
            Cooking our
          </h1>
          <h1 className="font-bold text-4xl md:text-6xl lg:text-7xl mb-8 text-white">
            Application
          </h1>
          <form onSubmit={handleSubmit}>
            <div className="relative flex items-center">
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter your email"
                className="p-3 border border-gray-300 rounded-full shadow-sm pr-32 md:pr-16 w-full"
                required
              />
              <button
                type="submit"
                className="absolute right-0 top-0 bottom-0 bg-black text-white px-4 py-3 rounded-full shadow hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-500"
              >
                Notify Me
              </button>
            </div>
            {message && (
              <p
                className={`mt-4 text-base font-medium text-center ${
                  messageType === "error" ? "text-red-500" : "text-white"
                }`}
              >
                {message}
              </p>
            )}
          </form>
          <div className="flex justify-center space-x-4 mt-6">
            <a
              href="https://www.facebook.com/nonstockorg/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faFacebook}
                className="text-white text-2xl hover:text-blue-500"
              />
            </a>
            <a
              href="https://www.instagram.com/nonstockorg/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                className="text-white text-2xl hover:text-pink-500"
              />
            </a>
          </div>
        </div>
        <div className="mt-8 md:mt-0">
          <img
            src={feature}
            alt="NonStock Application"
            className="rounded-2xl shadow-lg w-64 p-1 bg-white/50"
          />
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
